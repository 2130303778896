import React from "react"

import BlockContent from "../blockContent/blockContent"
import FormattedLink from "../formattedLink/formattedLink"
import Wrapper from "../layout/wrapper/wrapper"

import styles from "./resellers.module.scss"

const Resellers = ({ resellers }) => (
  <Wrapper>
    <h2>Resellers</h2>
    <div className="grid fullgap">
      {resellers?.map(item => (
        <div key={item.title} className="gridItem small-12 medium-6 large-4">
          <h3 className={styles.itemTitle}>{item.title}</h3>
          {item.address && <BlockContent blocks={item.address} />}
          {item.url && <FormattedLink link={item.url} label={item.linkLabel} type="link-pink" />}
        </div>
      ))}
    </div>
  </Wrapper>
)

export default Resellers
