import React from "react"
import cx from "classnames"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import FormattedLink from "../formattedLink/formattedLink"
import Wrapper from "../layout/wrapper/wrapper"

import styles from "./quote.module.scss"

const Quote = ({ quote, logo, title, background, slug, author }) => (
  <Wrapper background={background}>
    <div className="grid">
      <div className="gridItem small-12 medium-10 medium-offset-1">
        <div className={cx(styles.quote, styles[background])}>
          {logo ? (
            <Img
              className={styles.logo}
              fluid={logo?.asset.fluid}
              alt={logo?.alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          ) : null}
          <p className={styles.text}>“{quote}”</p>
          {author ? <p className={styles.author}>- {author}</p> : null}
          {slug && title ? (
            <FormattedLink link={`customer-stories/${slug.current}`} type="link-pink">
              See how Gomo transformed the eLearning program for {title}
            </FormattedLink>
          ) : null}
        </div>
      </div>
    </div>
  </Wrapper>
)

export default Quote

Quote.propTypes = {
  slug: PropTypes.object,
  background: PropTypes.string.isRequired,
  logo: PropTypes.object,
  title: PropTypes.string,
}
