import React from "react"
import cx from "classnames"
import Img from "gatsby-image"
import Reveal from "react-reveal/Reveal"

import Wrapper from "../layout/wrapper/wrapper"

import styles from "./clients.module.scss"

export const Clients = ({ title, client, background }) => (
  <Wrapper background={background}>
    <h2 className={styles.title}>{title}</h2>
    <Reveal effect="fadeInDown">
      <div className={cx("grid", styles.logos)}>
        {client.map(client => (
          <div key={client.logo.alt} className="gridItem small-6 medium-3 large-2">
            <Img className={styles.image} fluid={client.logo.asset.fluid} alt={client.logo.alt} />
          </div>
        ))}
      </div>
    </Reveal>
  </Wrapper>
)

export default Clients
