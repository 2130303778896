import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo/seo"
import Layout from "../components/layout/layout"
import Clients from "../components/clients/clients"
import Form from "../components/form/form"
import BlockContent from "../components/blockContent/blockContent"
import Hero from "../components/hero/heroSimple/heroSimple"
import Quote from "../components/quote/quote"
import Resellers from "../components/resellers/resellers"

import styles from "./contact.module.scss"

const Contact = ({ data: { sanityContact: contact } }) => (
  <Layout>
    <Seo isBlogPost={false} title={contact.title} {...contact.seo} />
    <div className={styles.wrapper}>
      <div className="grid">
        <div className="gridItem small-12">
          <Hero blocks={contact.hero[0].children} />
        </div>
        <div className="gridItem small-12 medium-6">
          <div className={styles.textWrapper}>
            <BlockContent blocks={contact._rawText} />
          </div>
        </div>
        <div className="gridItem small-12 medium-6">
          <div>
            <Form link={contact.form.link} title={contact.form.title} noPadding />
          </div>
        </div>
      </div>
    </div>
    {contact.quote ? <Quote {...contact.quote?.tabs?.content} background="blue" /> : null}
    {contact.clients.client.length ? <Clients {...contact.clients} /> : null}
    {contact.resellers.length ? <Resellers resellers={contact.resellers} /> : null}
  </Layout>
)

export const query = graphql`
  query($slug: String!) {
    sanityContact(slug: { current: { eq: $slug } }) {
      id
      title
      form {
        ...formFragment
      }
      _rawText
      hero: _rawHero
      quote {
        ...quoteFragment
      }
      clients {
        title
        client {
          logo {
            alt
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      resellers {
        url
        title
        linkLabel
        address: _rawAddress
      }
      seo {
        canonical
        description
        metaTitle
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
        removeSitemap
      }
    }
  }
`

export default Contact
